<template>
  <v-container class="ma-0 pa-0">
    <v-card class="ma-0 pa-5">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <img class="mag-glass mr-3 self-center" src='@/assets/img/mag-glass.png'/>
            <h3>Search Stories</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form
              @submit.prevent="submit"
              ref="search"
              v-model="valid"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="searchParams.firstName" label="First Name"></v-text-field>
                  <v-text-field v-model="searchParams.lastName" label="Last Name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="searchParams.middleName" label="Middle Name"></v-text-field>
                  <v-text-field v-model="searchParams.maidenName" label="Maiden Name"></v-text-field>
                </v-col>
              </v-row>
              <v-text-field v-model="searchParams.nickname" label="Nickname"></v-text-field>
              <v-text-field v-model="searchParams.dob" label="Born" :type="'date'" :max="new Date().toISOString().split('T')[0]"></v-text-field>
              <v-text-field v-model="searchParams.dod" label="Deceased" :type="'date'" :max="new Date().toISOString().split('T')[0]"></v-text-field>
              <v-select v-model="searchParams.gender" label="Gender" :items="['Male', 'Female', 'Non-Binary']"/>
              <v-select v-if="!searchParams.species && !searchParams.breed" v-model="searchParams.race" label="Heritage" :items="require('@/assets/data/races.json')" return-object/>
              <v-select v-if="!searchParams.race" v-model="searchParams.species" label="Species" :items="['Dog', 'Cat', 'Other']"/>
              <v-text-field v-if="!searchParams.race" v-model="searchParams.breed" label="Breed" :type="'text'"></v-text-field>
              <v-text-field v-model="searchParams.homeTown" label="Home Town"></v-text-field>
              <v-select v-model="searchParams.branch" label="Military Branch" :items="require('@/assets/data/branches.json')" return-object/>
              <v-select v-if="searchParams.branch" v-model="searchParams.rank" label="Highest Grade/Rank" :items="!searchParams.branch ? [] : require(`@/assets/data/${searchParams.branch.rankPath}/ranks.json`)" return-object/>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="searchParams.servedFrom" type="number" label="Served From" persistent-placeholder placeholder="yyyy"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="searchParams.servedTo" type="number" label="Served To" persistent-placeholder placeholder="yyyy"></v-text-field>
                </v-col>
              </v-row>
              <v-text-field v-model="searchParams.bootCamp" label="Boot Camp"></v-text-field>
              <v-text-field v-model="searchParams.deployments" label="Deployment"></v-text-field>
              <!-- <v-select v-model="award" label="Award" :items="!searchParams.branch ? [] : require(`@/assets/data/${searchParams.branch.rankPath}/awards.json`)" return-object/> -->
              <!-- Buttons -->
              <v-row class="justify-space-between ma-0">
                <v-btn
                  @submit.prevent="submit"
                  type="submit"
                  :loading="loading"
                  class="blue darken-3 mt-3 white--text"
                >
                  Search
                </v-btn>
                <v-btn
                  :disabled="loading"
                  class="blue darken-3 mt-3 white--text"
                  @click="resetFilters"
                >
                  Reset Filters
                </v-btn>
              </v-row>
              <div v-if="error" class="red">{{ error }}</div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Search',

    components: {
      //
    },

    data () {
      return {
        error: null,
        loading: false,
        panel: [0],
        searchParams: {
          award: '',
          bootCamp: '',
          branch: '',
          deployments: '',
          dob: '',
          dod: '',
          firstName: '',
          gender: '',
          homeTown: '',
          lastName: '',
          maidenName: '',
          middleName: '',
          nickname: '',
          race: '',
          rank: '',
          servedFrom: '',
          servedTo: '',
        },
        valid: false
      }
    },

    methods: {
      async submit () {this.error = ''
        this.$refs.search.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          this.$store.dispatch("searchStories", { params: this.searchParams })
          .then((resp) => {
            this.loading = false

            // If already at search results, only update parent
            if (this.$router.currentRoute.path === '/search-results') {
              this.panel = []
              this.$emit('updateSearch', resp)
            // Otherwise, navigate to search results page, passing the received story IDs
            } else {
              this.$router.push({ name: 'SearchResults', params: { results: resp } })
            }
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      },

      submitWithStory (firstName, lastName, createdStoryID) {
        this.firstName = firstName
        this.lastName = lastName

        this.submit(null, createdStoryID)
      },

      resetFilters () {
        this.searchParams = {
          award: '',
          bootCamp: '',
          branch: '',
          deployments: '',
          dob: '',
          dod: '',
          firstName: '',
          gender: '',
          homeTown: '',
          lastName: '',
          maidenName: '',
          middleName: '',
          nickname: '',
          race: '',
          rank: '',
          servedFrom: '',
          servedTo: '',
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mag-glass {
    max-width: 25px;
  }
</style>
